import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { SmsComponent } from './sms/sms.component';
import { WhatsappComponent } from './whatsapp/whatsapp.component';
import { PayComponent } from './pay/pay.component';
import { DashComponent } from './dash/dash.component';
import { OopsComponent } from './oops/oops.component';


const routes: Routes = [
{path:'login',component:LoginComponent},
{path:'signup',component:SignupComponent},
{path:'sms',component:SmsComponent},
{path:'whatsapp',component:WhatsappComponent},
{path:'pay',component:PayComponent},
{path:'dash',component:DashComponent},
{path:'',component:LoginComponent},
{path:'**',component:OopsComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
