import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.css']
})
export class SmsComponent implements OnInit {

  constructor() { }
  text;textlength;audience;
  help;
  ngOnInit() {
  this.help='is-info';
  }
  checktextlength(text){
  	this.textlength=text.length;
  	if(this.textlength>160){
  		this.textlength=160-this.textlength;
  		this.help='is-danger';
  	}
  	else{
  		this.help='is-info';
  	}
  }

}
